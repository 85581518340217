import ModelBase from "@/apps/core/models/modelBase.js";


class KomponenBase extends ModelBase {
  constructor(modelName, model, requiredFields, nonRequiredFields) {
    let modelBase = { nama: ""};
    Object.assign(model, modelBase);
    let requiredFieldsBase = ["nama"];
    requiredFields = requiredFields.concat(requiredFieldsBase);
    super(modelName, model, requiredFields, nonRequiredFields);
  }
}

export default KomponenBase;